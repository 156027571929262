export enum FileFormat {
  PDF = 'PDF',
  DOCX = 'DOCX',
  PPTX = 'PPTX',
  XLSX = 'XLSX',
  CSV = 'CSV',
  XLS = 'XLS',
  ZIP = 'ZIP',
  JPG = 'JPG',
  PNG = 'PNG',
  TXT = 'TXT',
  EDI = 'EDI',
  DSN = 'DSN'
}
