import { useToast } from 'vue-toastification'
import AuthenticationService from './AuthenticationService'
import {
  ERROR_KEYS_BY_CODE,
  ERROR_CODES,
  ERROR_DETAILS_BY_CODE
} from '~/constants/errors'
import { MIME_TYPE_BY_FORMAT } from '~/constants/fileFormats'
import type { FileFormat } from '~/types/fileFormats'

export const downloadFile = (url: string, fileName?: string) => {
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  if (fileName) {
    downloadLink.download = fileName
  }
  downloadLink.click()
}

export const getAcceptedFileTypes = (fileFormats: FileFormat[]) =>
  fileFormats.map(fileFormat => MIME_TYPE_BY_FORMAT[fileFormat]).join(', ')

export const uploadFiles = async (
  files: File[],
  uploadUrl: string,
  isSingleFile: boolean,
  extraDataToSend: { [key: string]: string | null } = {}
) => {
  const token = await AuthenticationService.getAuthenticationToken()
  const formData = new FormData()

  if (isSingleFile) {
    formData.append('file', files[0]!)
  } else {
    files.forEach(file => formData.append('files', file))
  }

  Object.entries(extraDataToSend).forEach(([key, value]) => {
    // We don't want to send empty/null/undefined values as they will be sent as strings
    if (value) {
      formData.append(key, value)
    }
  })

  try {
    const response = await fetch(uploadUrl, {
      method: 'POST',
      body: formData,
      headers: [['Authorization', `Bearer ${token}`]]
    })

    if (!response.ok) {
      const error = await response.json()
      throw new Error(error.message)
    }

    return await response.json()
  } catch (error: any) {
    const toast = useToast()
    const { $t } = useNuxtApp()
    if (
      error.message.includes(
        ERROR_KEYS_BY_CODE[ERROR_CODES.FILE_UPLOAD_WRONG_TYPE_OR_SIZE]
      )
    ) {
      toast.error(
        $t(
          ERROR_DETAILS_BY_CODE[ERROR_CODES.FILE_UPLOAD_WRONG_TYPE_OR_SIZE]
            .messageKey
        )
      )
    } else {
      toast.error($t('settings.integrations.dataSources.error.unknown'))
      throw error
    }
  }
}
