import { FileFormat } from '~/types/fileFormats'

export const MIME_TYPE_BY_FORMAT: { [key in FileFormat]: string } = {
  [FileFormat.PDF]: 'application/pdf',
  [FileFormat.DOCX]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [FileFormat.PPTX]:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  [FileFormat.XLSX]:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [FileFormat.CSV]: 'text/csv',
  [FileFormat.XLS]: 'application/vnd.ms-excel',
  [FileFormat.ZIP]: 'application/zip',
  [FileFormat.JPG]: 'image/jpeg',
  [FileFormat.PNG]: 'image/png',
  [FileFormat.TXT]: 'text/plain',
  [FileFormat.DSN]: '',
  [FileFormat.EDI]: ''
}
